import { BaseApi } from "@/api/base/base";
import * as PATH_URL from "@/api/constants/path";

export const userList = async () => {
  try {
    const result = await BaseApi.get(PATH_URL.USER_LIST)
    return result;
  } catch (e) {
    return Promise.reject(e);
  }
}

export const editUser = async (params) => {
  try {
    const result = await BaseApi.post(PATH_URL.USER, params)
    return result.data;
  } catch (e) {
    return Promise.reject(e);
  }
}

export const delUser = async (userId) => {
  try {
    const result = await BaseApi.delete(PATH_URL.USER, {
      params: {
        userId
      }
    })
    return result.data;
  } catch (e) {
    return Promise.reject(e);
  }
}

export const roleList = async (pageNo = 1, pageSize = 10) => {
  try {
    const result = await BaseApi.get(PATH_URL.ROLE_LIST, {
      params: {
        pageNo, pageSize
      }
    })
    return result;
  } catch (e) {
    return Promise.reject(e);
  }
}

export const editRole = async (params) => {
  try {
    const result = await BaseApi.post(PATH_URL.ROLE, params);
    return result.data;
  } catch (e) {
    return Promise.reject(e);
  }
}

export const delRole = async (roleId) => {
  try {
    const result = await BaseApi.delete(PATH_URL.ROLE, {
      params: {
        roleId
      }
    })
    return result.data;
  } catch (e) {
    return Promise.reject(e);
  }
}

export const distributeRoleToUser = async (params) => {
  try {
    const result = await BaseApi.post(PATH_URL.DISTRIBUTE_ROLE, params)
    return result.data;
  } catch (e) {
    return Promise.reject(e);
  }
}

export const appUserRelationList = async () => {
  try {
    const result = await BaseApi.get(PATH_URL.APP_USER_RELATION)
    return result.data;
  } catch (e) {
    return Promise.reject(e);
  }
}
