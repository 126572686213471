import * as types from "@/store/constants/user_types";
import * as filter_types from "@/store/constants/filter_types";
import md5 from "js-md5";
import {login, checkToken} from "@/api/service/login";
import {Router} from "@/main";
import {fetchCategoryList, getAppList} from "@/api/service/app";
import {userList} from "@/api/service/user";
import Toast from "@/utils/toast";
import {removeNoSenseKeys} from "@/utils/common";

export default {
    async [types.LOGIN]({ dispatch }: any, payLoad: any) {
        try {
            const {username, password, phoneCode, redirectedFrom} = payLoad;
            const result = await login({
                username,
                phoneCode,
                password: md5(password),
            });

            dispatch(types.HANDLE_LOGIN_SUCCESS, {...result, username, redirectedFrom})
        } catch (e) {
            Toast.show("error", e);
        }
    },

    async [types.HANDLE_LOGIN_SUCCESS]({ commit }: any, payLoad: any) {
        const {username, roleLevel, token, userId, url, redirectedFrom} = payLoad;
        console.log("redirectedFrom", redirectedFrom);
        if (redirectedFrom) {
            console.log("为啥不执行呢")
            Router.replace(redirectedFrom);
        } else {
            Router.replace({
                name: "app"
            })
        }
        commit(types.UPDATE_USERNAME, username);
        commit(types.UPDATE_LEVEL, roleLevel);
        commit(types.UPDATE_TOKEN, token);
        commit(types.UPDATE_TOKEN_RECORD_TIMESTAMP, new Date().getTime())
        commit(types.UPDATE_CURRENT_USER_ID, userId);
        commit(types.UPDATE_PRIVATE_URL, url);
    },

    async [types.UPDATE_APP_LIST]({ commit, getters }: any, payLoad: any) {
        try {
            const {
                appType,
                appName,
                categoryId,
                orderByColumn,
                sort,
                userId,
                viewCollect
            } = payLoad;
            const result: any = await getAppList(removeNoSenseKeys({
                appType: appType !== "全部" ? appType : null,
                appName,
                categoryId: categoryId !== -1 ? categoryId: null,
                orderByColumn,
                sort,
                viewCollect,
                userId: userId !== -1 ? userId : null
            }), 1, 1000);
            const {data, extraInfo} = result;
            commit(types.UPDATE_APP_LIST, data);
            commit(filter_types.UPDATE_NATIVE_READ_LIST);
            if (data.length > 0 && (
                !getters[types.GETTER_CURRENT_APP_ID] ||
                !data.find((v: any) => v.appId === getters[types.GETTER_CURRENT_APP_ID]))
            ) {
                commit(types.UPDATE_CURRENT_APP_ID, data[0].appId);
            }
            commit(filter_types.UPDATE_PLATFORM_COUNTS, [{
                title: "全部",
                count: extraInfo.total
            }, {
                title: "iOS",
                count: extraInfo.iOS
            }, {
                title: "Android",
                count: extraInfo.Android
            }])
            commit(filter_types.UPDATE_APP_EDITORS, extraInfo.appEditors);
        } catch (e) {
            const showTips = payLoad && payLoad.showTips;
            showTips && Toast.show("error", e);
        }
    },
    async [types.UPDATE_USER_LIST]({ commit }: any, payLoad: any) {
        try {
            const refresh = payLoad && payLoad.refresh;
            const result = await userList();
            refresh && commit(types.UPDATE_USER_LIST, result.data);
            return result;
        } catch (e) {
            const showTips = payLoad && payLoad.showTips;
            showTips && Toast.show("error", e);
        }
    },
    async [types.UPDATE_CATEGORY_LIST]({ commit }: any, payLoad: any) {
        try {
            const result = await fetchCategoryList();
            commit(types.UPDATE_CATEGORY_LIST, result);
        } catch (e) {
            const showTips = payLoad && payLoad.showTips;
            showTips && Toast.show("error", e);
        }
    },
    // payload是用来区分是否跳转
    async [types.CHECK_TOKEN]({ getters }: any, payload: any) {
        try {
            const is_in_h5sdk = payload && payload.is_in_h5sdk;
            const now_time = new Date().getTime();
            const token_record_time = getters[types.GETTER_TOKEN_RECORD_TIMESTAMP];
            const token = getters[types.GETTER_TOKEN];
            // 如果存在存储的token时间，并且当前时间-存储token的时间大于3天，直接报错，小于三天则校验token
            if (token_record_time && (now_time - token_record_time > 7 * 24 * 60 * 60 * 1000)) {
                throw "账号登录信息已过期，即将跳转登录"
            } else {
                if (!token) {
                    throw "账号登录信息已过期，即将跳转登录"
                } else {
                    await checkToken(is_in_h5sdk);
                }
            }
            return true;
        } catch (e) {
            const is_publish_page = payload && payload.is_publish_page;
            if (is_publish_page) {
                setTimeout(() => {
                    Router.push("/login");
                }, 1000);
                Toast.show("error", e)
            }
            return false;
        }
    }
}
