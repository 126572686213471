export const GETTER_FILTER = "GETTER_FILTER";
export const GETTER_STATE_COLLAPSED = "GETTER_STATE_COLLAPSED";
export const UPDATE_FILTER = "UPDATE_FILTER";
export const UPDATE_COLLAPSED = "UPDATE_COLLAPSED";
export const UPDATE_PLATFORM_COUNTS = "UPDATE_PLATFORM_COUNTS";
export const GETTER_PLATFORM_COUNTS = "GETTER_PLATFORM_COUNTS";
export const UPDATE_APP_EDITORS = "UPDATE_APP_EDITORS";
export const GETTER_APP_EDITORS = "GETTER_APP_EDITORS";
export const UPDATE_NOTIFICATION_LIST = "UPDATE_NOTIFICATION_LIST";
export const UPDATE_NATIVE_READ_LIST = "UPDATE_NATIVE_READ_LIST";
export const GETTER_NATIVE_READ_LIST = "GETTER_NATIVE_READ_LIST";
export const GETTER_NOTIFICATION_LIST = "GETTER_NOTIFICATION_LIST";
export const READ_NOTIFICATION = "READ_NOTIFICATION";

export const getDefaultFilterState = () => {
    return {
        appType: "全部",
        orderByColumn: "update_time",
        sort: "DESC",
        categoryId: -1,
        userId: -1,
        viewCollect: null,
        refresh: false
    }
}
