import * as types from "@/store/constants/filter_types.ts";
export default {
    [types.UPDATE_COLLAPSED](state: any, payLoad: any) {
        state.collapsed = payLoad;
    },
    [types.UPDATE_FILTER](state: any, payLoad: any) {
        state.filters = {...state.filters, ...payLoad};
    },
    [types.UPDATE_PLATFORM_COUNTS](state: any, payLoad: any) {
        state.platform_counts = payLoad
    },
    [types.UPDATE_APP_EDITORS](state: any, payLoad: any) {
        state.app_editors = payLoad
    },
    [types.UPDATE_NOTIFICATION_LIST](state: any, payLoad: any) {
        state.notifications = payLoad;
    },
    [types.UPDATE_NATIVE_READ_LIST](state: any, payLoad: any) {
        state.native_read_list = payLoad ? [...state.native_read_list, payLoad] : [];
    }
}
