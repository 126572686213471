import axios from "axios";
import config from "../../config/index.js";
import * as types from "@/store/constants/user_types";
import {store, Router} from "@/main";
import Toast from "@/utils/toast";

export const BaseApi = axios.create({
  baseURL: config.BASE_URL,
  timeout: 15000,
});

// 添加请求拦截器
BaseApi.interceptors.request.use(
  (config) => {
    const token = store.getters[types.GETTER_TOKEN];
    const supply = config.supply;
    return {
      ...config,
      headers: supply ? {
        ...supply,
        token
      } : {
        token
      }
    }
  },
  (error) => {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 开始解密的处理和返回
BaseApi.interceptors.response.use(
  (response) => {
    try {
      const { data, config } = response;
      if (data.code === 200) {
        return data;
      } else if (data.code === 401 && data.msg.includes("token")) {
        const prevent_redirect = config && config.supply && config.supply.prevent_redirect;
        console.log("prevent_redirect", prevent_redirect, config);
        if (!prevent_redirect) {
          Toast.show("error", "账号登录信息已过期，即将跳转登录~");
          setTimeout(() => {
            Router.push("/login");
          }, 1000);
        }
        return Promise.reject(data.msg);
      } else if (data.code === 10028) {
        setTimeout(() => {
          Router.push("/403");
        });
        return Promise.reject(data.msg);
      } else if (data.code === 304) {
        return Promise.reject(data);
      } else if (config && config.url.includes("api/udid/export")) {
        return response;
      } else {
        return Promise.reject(data.msg);
      }
    } catch (error) {
      console.log("interceptors error", error);
      return Promise.reject(error);
    }
  },
  (error) => {
    // 对响应错误做点什么
    return Promise.reject(error);
  }
);


