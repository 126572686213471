// export const getRanNumChat = () => {
//   const result = [];
//   for (let i = 0; i < 4; i++) {
//     const ranNum = Math.ceil(Math.random() * 25);
//     result.push(String.fromCharCode(Math.random() < 0.5 ? 97 : 65 + ranNum));
//   }
//   return result.join('');
// }

export function getRanNumChat(len, charSet) {
  charSet = charSet || 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
  let randomString = '';
  for (let i = 0; i < len; i++) {
    let randomPoz = Math.floor(Math.random() * charSet.length);
    randomString += charSet.substring(randomPoz,randomPoz+1);
  }
  return randomString;
}

export const getRandomNum = (num) => {
  let randomNum = '';
  for (let i = 0; i < num; i++) {
    randomNum += String(Math.floor(Math.random() * 10))
  }
  return randomNum;
}

export function isString(x) {
  return Object.prototype.toString.call(x) === "[object String]";
}
let isIosCache = null; // default true
export function isIos() {
  if (isIosCache !== null) {
    return isIosCache;
  } else {
    const u = window.navigator.userAgent;
    const isHigherVersionPad =
      window.navigator.platform === "MacIntel" &&
      window.navigator.maxTouchPoints > 1;
    const isiOS =
      !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) || isHigherVersionPad; // ios终端
    isIosCache = isiOS;
    return isiOS;
  }
}

export const objectToQuery = (obj, encode = true) => {
  const pushArr = [];
  if (Object.prototype.toString.call(obj) === "[object Object]") {
    for (const key in obj) {
      if (Object.prototype.toString.call(obj[key]) === "[object Object]") {
        pushArr.push(
          `${key}=${
            encode
              ? encodeURIComponent(JSON.stringify(obj[key]))
              : JSON.stringify(obj[key])
          }`
        );
      } else if (obj[key] !== undefined) {
        pushArr.push(
          `${key}=${encode ? encodeURIComponent(obj[key]) : obj[key]}`
        );
      }
    }
    return pushArr.join("&");
  } else {
    throw new Error("expect object type param");
  }
};

export const getSuffix = (file_path) => {
  return file_path.substring(file_path.lastIndexOf("."));
}

export function throttle(func, wait, immediate) {
  let timerId = null; // 定时器 ID

  return function(...args) {
    if (timerId !== null) {
      clearTimeout(timerId); // 清除定时器
    }
    if (immediate && timerId === null) {
      func.apply(this, args); // 立即执行函数
    }
    timerId = setTimeout(() => {
      if (!immediate) {
        func.apply(this, args); // 延迟执行函数
      }
      timerId = null; // 清除定时器 ID
    }, wait); // 延迟执行函数
  };
}

export const isMobileDevice = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  const mobileKeywords = ['iphone', 'ipod', 'android', 'blackberry', 'windows phone'];
  return mobileKeywords.some(keyword => userAgent.includes(keyword));
}
