import { GetterTree } from "vuex";
import * as types from "@/store/constants/filter_types";
import {isMobileDevice} from "@/utils/utils";

const Getters: GetterTree<any, any> = {
    [types.GETTER_FILTER](state) {
        return state.filters;
    },
    [types.GETTER_STATE_COLLAPSED](state) {
        return !isMobileDevice() || state.collapsed;
    },
    [types.GETTER_PLATFORM_COUNTS](state) {
        return state.platform_counts;
    },
    [types.GETTER_APP_EDITORS](state) {
        return state.app_editors;
    },
    [types.GETTER_NOTIFICATION_LIST](state) {
        return state.notifications;
    },
    [types.GETTER_NATIVE_READ_LIST](state) {
        return state.native_read_list;
    }
}

export default Getters;
