import * as types from "@/store/constants/user_types.ts";

export default {
    [types.UPDATE_USERNAME](state: any, payLoad: any) {
        state.username = payLoad;
    },
    [types.UPDATE_TOKEN](state: any, payLoad: any) {
        state.token = payLoad;
    },
    [types.UPDATE_TOKEN_RECORD_TIMESTAMP](state: any, payLoad: any) {
        state.token_record_timestamp = payLoad;
    },
    [types.UPDATE_LEVEL](state: any, payLoad: any) {
        state.roleLevel = payLoad;
    },
    [types.UPDATE_CURRENT_USER_ID](state: any, payLoad: any) {
        state.userId = payLoad;
    },
    [types.UPDATE_PRIVATE_URL](state: any, payLoad: any) {
        state.privateUrl = payLoad;
    },
    [types.UPDATE_APP_LIST](state: any, payLoad: any) {
        state.appList = payLoad;
    },
    [types.UPDATE_USER_LIST](state: any, payLoad: any) {
        state.userList = payLoad;
    },
    [types.UPDATE_CURRENT_APP_ID](state: any, payLoad: any) {
        state.currentChooseAppId = payLoad;
    },
    [types.RESET_STORE](state: any) {
        Object.assign(state, types.getDefaultUserState())
    },
    [types.UPDATE_CATEGORY_LIST](state: any, payLoad: any) {
        state.categoryList = payLoad;
    },
    [types.UPDATE_VERSION_TOKEN](state: any, payLoad: any) {
        state.versionToken = payLoad;
    }
}
