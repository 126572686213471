import actions from "@/store/actions/user_actions";
import getters from "@/store/getters/user_getters";
import mutations from "@/store/mutations/user_mutations";
import * as types from "@/store/constants/user_types";

export default {
    state: types.getDefaultUserState(),
    actions,
    mutations,
    getters
};
