import * as types from "@/store/constants/filter_types";
import {
    get_notification_list,
    read_notification
} from "@/api/service/notification";
import { UPDATE_APP_LIST } from "@/store/constants/user_types";

export default {
    async [types.UPDATE_NOTIFICATION_LIST]({commit}: any, payLoad: any) {
        try {
            const {state} = payLoad;
            const result = await get_notification_list({
                current: 1,
                size: 100,
                state
            });
            commit(types.UPDATE_NOTIFICATION_LIST, result.records);
        } catch (e) {
            // Toast.show("error", e);
        }
    },
    async [types.READ_NOTIFICATION]({dispatch, getters}: any, payLoad: any) {
        try {
            const {id, appId} = payLoad;
            await read_notification(
                id ?
                    {
                        id, state: 'IGNORE'
                    } :
                    appId ?
                        {
                            appId, state: 'READ'
                        } :
                        {
                            state: 'READ'
                        }
            );
            dispatch(types.UPDATE_NOTIFICATION_LIST, {
                state: "NOT_READ"
            });
            if (!id && !appId) {
                const filters = getters[types.GETTER_FILTER];
                // 不存在id和appId，视为一键读所有，需刷新app列表
                dispatch(UPDATE_APP_LIST, filters);
            }
        } catch (e) {
            console.log("error", e);
        }
    }
}
