import localForage from "localforage";
import VuexPersistence from "vuex-persist";

export default new VuexPersistence({
  storage: localForage as any,
  strictMode: false,
  asyncStorage: true,
  reducer(val: any) {
    return {
      user_info_module: val.user_info_module // 这里只对user模块作数据持久化
    }
  }
});
