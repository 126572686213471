export const openNewTab = (url: string) => {
  const lark = window.lark;
  console.log("lark", lark);
  if (typeof lark !== 'undefined' && lark) {

    // 使用飞书内置的方法打开链接
    if (lark.openLink) {
      lark.openLink({
        url
      });
    } else {
      // 飞书客户端
      window.location.href = url;
    }
  } else {
    // 如果不在飞书环境中，则使用 window.open 打开新标签页
    window.open(url, "_blank");
  }
}
