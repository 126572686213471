import * as types from "@/store/constants/filter_types";
import getters from "@/store/getters/filter_getters";
import mutations from "@/store/mutations/filter_mutations";
import actions from "@/store/actions/filter_actions";

export default {
    state: {
        filters: types.getDefaultFilterState(),
        platform_counts: [],
        notifications: [],
        collapsed: false,
        native_read_list: [] // 本地已读取通知的app列表
    },
    getters,
    mutations,
    actions
}
