import { createApp } from 'vue'
import App from './App.vue'
import Router from './router'
import store from './store'
// import Antd from 'ant-design-vue';
// import 'ant-design-vue/dist/antd.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import "@/style/common.css";
import { initDefaultRouter } from "@/services/launch/initRouter.ts";

initDefaultRouter().then(() => {
    createApp(App).use(store).use(Router).mount('#app')
})

export { Router, store };




