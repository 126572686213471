import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import App from "@/views/app/index.vue";
// import Publish from "@/views/publish/index.vue";
// import PublishSuccess from "@/views/publish/publishSuccess.vue";
// import Test from "@/views/test/Test.vue";
// import Package from "@/views/package/index.vue";
import Login from "@/views/login/login.vue";
import OAuth from "@/views/oauth/index.vue";
// import Role from "@/views/user/role.vue";
// import User from "@/views/user/user.vue";
// import Permission from "@/views/user/permission.vue";
import Home from "@/views/Home.vue";
// import Setting from "@/views/user/setting.vue";
// import ErrorPage from "@/views/403/index.vue";
import {store} from "@/main";
import * as types from "@/store/constants/user_types";
import Toast from "@/utils/toast";
import AppHome from "@/views/app/appHome.vue";
// import UDID from "@/views/app/UDID.vue";
// import Category from "@/views/app/category.vue";
// import Notification from "@/views/app/notification.vue"

const Publish = () => import("@/views/publish/index.vue");
const PublishSuccess = () => import("@/views/publish/publishSuccess.vue");
const Package = () => import("@/views/package/index.vue");
const Role = () => import("@/views/user/role.vue");
const User = () => import("@/views/user/user.vue");
const Permission = () => import("@/views/user/permission.vue");
const Setting = () => import("@/views/user/setting.vue");
const UDID = () => import("@/views/app/UDID.vue");
const Category = () => import("@/views/app/category.vue");
const Notification = () => import("@/views/app/notification.vue");
const ErrorPage = () => import("@/views/403/index.vue");
const Certification = () => import("@/views/user/certification.vue");
// const Login = () => import("@/views/login/login.vue");

const routes: Array<RouteRecordRaw> = [{
    path: "/home",
    name: "Home",
    component: Home,
    children: [
        {
            path: "",
            name: "appHome",
            component: AppHome,
            redirect: "app",
            children: [{
                path: "app",
                name: "app",
                component: App
            }, {
                path: "category",
                name: "category",
                component: Category
            }, {
                path: "notification",
                name: "notification",
                component: Notification
            }]
        }, {
            path: "setting",
            name: "setting",
            component: Setting,
            redirect: "/home/setting/user-manage",
            children: [{
                path: "role-manage",
                name: "role",
                component: Role
            }, {
                path: "user-manage",
                name: "user",
                component: User
            }, {
                path: "distribute-user",
                name: "distribute",
                component: Permission
            }, {
                path: "udid",
                name: "udid",
                component: UDID
            }, {
                path: "certification",
                name: "certification",
                component: Certification
            }]
        }, {
            path: "/403",
            name: "403",
            component: ErrorPage
        }
        // , {
        //     path: "test",
        //     name: "test",
        //     component: Test
        // }
    ],
}, {
    path: "/publish",
    name: "publish",
    component: Publish
}, {
    path: "/publish-success",
    name: "PublishSuccess",
    component: PublishSuccess
}, {
    path: "/ios/:path",
    name: "iosPackage",
    component: Package
}, {
    path: "/android/:path",
    name: "androidPackage",
    component: Package
}, {
    path: "/login",
    name: "login",
    component: Login
}, {
    path: '/callback/feishu',
    name: 'auth',
    component: OAuth
}]

const router: any = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export function resetRouter () {
    const newRouter: any = createRouter({
        history: createWebHistory(process.env.BASE_URL),
        routes
    })
    router.matcher = newRouter.matcher // the relevant part
}

router.beforeEach((to: any) => {
    const roleLevel = store.getters[types.GETTER_LEVEL];
    if (roleLevel < 100 && (
        to.fullPath.includes("setting") ||
        to.name === "udid" ||
        to.name === "category"
    )) {
        Toast.show('error', "您没有权限访问");
        return false;
    }
    return true;
})

export default router
