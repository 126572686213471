import { createStore, createLogger } from 'vuex';
import user_info_module from "@/store/modules/user_module";
import filter_module from "@/store/modules/filter_module";
import vuexLocal from "@/store/plugins/vuex_persisit.ts";

const vuexPlugins = [vuexLocal.plugin];

vuexPlugins.push(
    createLogger({
      collapsed: true // vuex logger
    })
);

export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  plugins: vuexPlugins,
  modules: {
    user_info_module,
    filter_module
  }
})
