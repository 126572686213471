
import {Vue, Prop, Options, Watch} from "vue-property-decorator";
import {Action, Getter, Mutation} from "vuex-class";
import {DeleteOutlined} from '@ant-design/icons-vue';
import {
  GETTER_NOTIFICATION_LIST,
  READ_NOTIFICATION, UPDATE_NATIVE_READ_LIST
} from "@/store/constants/filter_types";
import moment from "moment";
import {GETTER_CURRENT_APP_ID, UPDATE_CURRENT_APP_ID} from "@/store/constants/user_types";
import {Button, Tooltip} from "ant-design-vue"
import {isMobileDevice} from "@/utils/utils";
@Options({
  components: {
    DeleteOutlined,
    AButton: Button,
    ATooltip: Tooltip
  }
})
export default class NotificationModal extends Vue {
  @Prop()
  private visible?: boolean;
  @Prop()
  private rightWidth?: number
  @Getter(GETTER_NOTIFICATION_LIST)
  private notificationList: any;
  @Action(READ_NOTIFICATION)
  private readNotification: any;
  @Mutation(UPDATE_CURRENT_APP_ID)
  private updateCurrentAppId: any;
  @Getter(GETTER_CURRENT_APP_ID)
  private currentAppId: any;
  @Mutation(UPDATE_NATIVE_READ_LIST)
  private updateNativeReadList: any;

  private modalVisible = false;

  get isMobile() {
    return isMobileDevice();
  }

  get activeStyle() {
    return this.isMobile ? {
      width: "300px",
      left: "-140px"
    } : {
      width: (this.rightWidth as number) * 2 + 19 + "px",
      left: "-" + this.rightWidth + "px"
    }
  }

  @Watch("visible")
  private toggleState() {
    this.modalVisible = !!this.visible;
  }

  private getCurrentTime(time: number) {
    console.log("moment().utcOffset()", moment(new Date()).format("YYYY-MM-DD HH:mm:ss"));
    return moment(time).format("YYYY-MM-DD HH:mm:ss")
  }

  private getCurrentSplitTime(time: number) {
    return moment(time).locale('zh-cn').from(new Date());
  }

  private linkApp(id: string) {
    if (id) {
      this.updateCurrentAppId(id);
      this.$router.push({
        name: "app"
      })
      if (id === this.currentAppId) {
        this.readNotification({appId: id});
        this.updateNativeReadList(id);
      }
      this.$emit("closeModal");
    }
  }

  private goMessage() {
    this.$router.push({
      name: "notification"
    })
    this.$emit("closeModal");
  }

  private _ignore(id: number, appId: string) {
    this.readNotification({id, appId});
  }

  private readAll() {
    this.readNotification({});
  }
}
